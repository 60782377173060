import React, { Fragment, useEffect, useState } from 'react';
import {
    Alert, Button, Col, Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
    Form, FormGroup, Input, Label
} from 'reactstrap';
import { getFetchHeadersAsync, getSimpleDateString, getWeekInfo, objectToArray, xhrRequest } from '../lib/util'
import ShipmentManager from './ShipmentManager.js';
import FileUpload from './forms/FileUpload'

export default function ShipmentsManager(props) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true);

    // general view
    const [purchaseOrders, setPurchaseOrders] = useState(null);
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
    const [scheduleData, setScheduleData] = useState(null);

    // active shipments view
    const [activeShipments, setActiveShipments] = useState(null);

    const [addingToScheduleEntry, setAddingToScheduleEntry] = useState(null);
    const [addingShipment, setAddingShipment] = useState(false);
    const [receivingScheduleEntry, setReceivingScheduleEntry] = useState(null);
    const [receivingScheduleShipment, setReceivingScheduleShipment] = useState(null);
    const [receivingShipment, setReceivingShipment] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);

    useEffect(() => {
        if (!purchaseOrders) populatePurchaseOrders();
    });

    // general view

    async function populatePurchaseOrders() {
        setPurchaseOrders({});

        try {
            const purchaseOrdersResponse = await fetch(`api/suppliers/${props.supplier.id}/purchaseorders`, await getFetchHeadersAsync());
            const purchaseOrdersData = await purchaseOrdersResponse.json();
            setPurchaseOrders(purchaseOrdersData.result);

            if (purchaseOrdersData.result?.length === 1)
                selectPurchaseOrder(purchaseOrdersData.result[0]);
            else if (!selectedPurchaseOrder && props.params?.purchaseOrderId) {
                const newSelection = purchaseOrdersData?.result?.find(p => p.id === parseInt(props.params.purchaseOrderId));
                await selectPurchaseOrder(newSelection);
            }

            if (props.activeView)
                await populateActiveShipments();
        }
        catch (error) {
            setErrorMessage(error?.message || error);
        }

        setLoading(false);
    }

    async function selectPurchaseOrder(po) {
        setSaveSuccess(false);
        setSelectedPurchaseOrder(po);
        if (!props.activeView)
            await populateScheduleData(po);
    }

    async function populateScheduleData(po) {
        setLoading(true);
        po = po ?? selectedPurchaseOrder;
        const scheduleResponse = await fetch(`api/suppliers/${props.supplier.id}/purchaseorders/${po.id}/shipments`, await getFetchHeadersAsync());
        const scheduleData = await scheduleResponse.json();
        scheduleData.result.forEach(s => s.weekData.weekInfo = getWeekInfo(s.weekData.releaseDate));
        setScheduleData(scheduleData.result);

        setLoading(false);
    }

    // active shipments view

    async function populateActiveShipments() {
        setActiveShipments({});
        try {
            const response = await fetch(`api/suppliers/${props.permissions.supplierId}/activeshipments`, await getFetchHeadersAsync());
            const data = await response.json();
            if (data.errors || data.error) throw data.error || data.title;
            let newResult = objectToArray(data.result);
            newResult.forEach(r => r.weeklyData.forEach(w => w.weekData.weekInfo = getWeekInfo(w.weekData.releaseDate)));
            setActiveShipments(newResult);
        }
        catch (error) {
            setErrorMessage(error?.message || error);
        }
    }

    // shipments

    function addShipment(scheduleEntry) {
        if (props.activeView && !!scheduleEntry) {
            setSelectedPurchaseOrder(purchaseOrders?.find(p => p.id === scheduleEntry.weekData.purchaseOrderId));
        }

        setSaveSuccess(false);
        setAddingToScheduleEntry(scheduleEntry);
        setAddingShipment(!!scheduleEntry);
    }

    function receiveShipment(scheduleEntry, shipment) {
        setSaveSuccess(false);
        setReceivingScheduleEntry(scheduleEntry);
        setReceivingScheduleShipment(shipment);
        setReceivingShipment(!!shipment);
    }

    async function submitNewShipment(shipQty, packingListNumber, files) {
        try {
            await xhrRequest({
                url: `api/suppliers/${props.supplier.id}/purchaseorders/${addingToScheduleEntry.weekData.purchaseOrderId}/shipments`,
                headers: await getFetchHeadersAsync('PUT'),
                body: {
                    purchaseOrderId: addingToScheduleEntry.weekData.purchaseOrderId,
                    releaseDate: addingToScheduleEntry.weekData.releaseDate,
                    shipQty,
                    packingListNumber
                },
                files: files,
                onProgress: onUploadProgress
            });
        }
        catch (error) {
            setErrorMessage(error?.message || error);
        }
        setAddingShipment(false);
        setAddingToScheduleEntry(null);
        setSaveSuccess(true);
        if (props.activeView)
            await populateActiveShipments();
        else
            await populateScheduleData();
    }

    async function submitReceivingShipment(receivedQty) {
        try {
            const response = await fetch(
                `api/suppliers/${props.supplier.id}/purchaseorders/${receivingScheduleEntry.weekData.purchaseOrderId}/shipments/${receivingScheduleShipment.shipment.id}`,
                await getFetchHeadersAsync('POST', { receivedQty }));
            const data = await response.json();
            if (data.errors || data.error) throw data.error || data.title;
        }
        catch (error) {
            setErrorMessage(error?.message || error);
        }
        setReceivingScheduleEntry(null);
        setReceivingScheduleShipment(null);
        setReceivingShipment(false);
        setSaveSuccess(true);
        await populateScheduleData();
    }

    function onUploadProgress(ev) {
        console.log(ev.loaded);
    }

    return loading ? <Label>Loading...</Label> : (
        <Fragment>
            {addingShipment && addingToScheduleEntry && !!selectedPurchaseOrder && (
                <AddShipmentForm
                    purchaseOrder={selectedPurchaseOrder}
                    scheduleEntry={addingToScheduleEntry}
                    onSubmit={submitNewShipment}
                    onCancel={() => addShipment(null)}
                />
            )}
            {receivingShipment && receivingScheduleEntry && receivingScheduleShipment && (
                <ReceiveShipmentForm
                    purchaseOrder={selectedPurchaseOrder}
                    scheduleEntry={receivingScheduleEntry}
                    shipment={receivingScheduleShipment}
                    onSubmit={submitReceivingShipment}
                    onCancel={() => receiveShipment(null)}
                />
            )}
            {!(props.activeView || addingShipment || receivingShipment) && (
                <Fragment>
                    <PurchaseOrderSelector
                        purchaseOrders={purchaseOrders}
                        selectedPurchaseOrder={selectedPurchaseOrder}
                        onChange={selectPurchaseOrder}
                    />
                    <ShipmentManager
                        permissions={props.permissions}
                        supplier={props.supplier}
                        schedule={scheduleData}
                        onAddShipment={addShipment}
                        onReceiveShipment={receiveShipment}
                    />
                </Fragment>
            )}
            {props.activeView && !(addingShipment || receivingShipment) && (
                <ShipmentManager
                    permissions={props.permissions}
                    supplier={props.supplier}
                    activeShipments={activeShipments}
                    onAddShipment={addShipment}
                    onReceiveShipment={receiveShipment}
                />
            )}
            {saveSuccess && (
                <div>
                    <Alert color="success" toggle={() => setSaveSuccess(false)}>
                        Saved successfully!
                    </Alert>
                </div>
            )}
            {errorMessage &&
                <div>
                    <Alert color="danger" toggle={() => setErrorMessage(null)}>
                        Error occurred! {errorMessage}
                    </Alert>
                </div>
            }
        </Fragment>
    );
}

export function PurchaseOrderSelector(props) {
    const [openState, setOpenState] = useState(false);
    return (
        <Fragment>
            <Dropdown isOpen={openState} toggle={() => setOpenState(!openState)}>
                <DropdownToggle caret>
                    {!!props.selectedPurchaseOrder ? `${props.selectedPurchaseOrder.poNumber} (${props.selectedPurchaseOrder.partNumber})` : 'Select purchase order'}
                </DropdownToggle>
                <DropdownMenu>
                    {props.purchaseOrders?.map(po => (
                        <DropdownItem key={po.id} onClick={() => props.onChange(po)}>{`${po.poNumber} (${po.partNumber})`}</DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </Fragment>
    );
}

export function AddShipmentForm(props) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [shipQty, setShipQty] = useState(0);
    const [packListNumber, setPackListNumber] = useState('');
    const [files, setFiles] = useState({});
    const MAX_FILE_SIZE_MB = 50;

    async function submitNewShipment() {
        props.onSubmit(shipQty, packListNumber, files);
    }

    function filesUpdated(files) {
        setFiles({ ...files });
    }

    function handleChange(ev) {
        ev.preventDefault();
        let { name, value } = ev.target;
        if (name === 'qty') setShipQty(value);
        else if (name === 'packListNumber') setPackListNumber(value);
        else setErrorMessage("Unsupported input received.");
    }

    function alertFilesTooLarge(badFiles) {
        const error = `Files exceeded the maximum size of ${MAX_FILE_SIZE_MB} megabytes and were not added: "${badFiles.join('", "')}"`;
        setErrorMessage(error);
    }

    return (
        <Fragment>
            <h4>{`Purchase Order ${props.purchaseOrder.poNumber} (${props.purchaseOrder.partNumber})`}</h4>
            <p>
                {`Week ${props.scheduleEntry.weekData.weekInfo.weekNumber} (${getSimpleDateString(props.scheduleEntry.weekData.releaseDate)}) - `}
                {props.scheduleEntry.shipments && props.scheduleEntry.shipments.length > 0 ? (
                    <Fragment>
                        {props.scheduleEntry.weekData.qtyRequired} required
                        <strong>
                            {` (${props.scheduleEntry.weekData.qtyRequired - props.scheduleEntry.shipments.reduce((accum, cur) => accum + cur.shipment.shipQty, 0)} remaining)`}
                        </strong>
                    </Fragment>
                ) : (
                    <strong>
                        {props.scheduleEntry.weekData.qtyRequired} required
                    </strong>
                )}
            </p>
            <Form>
                <FormGroup row>
                    <Label sm={2}>Qty</Label>
                    <Col sm={10}>
                        <Input
                            type="number"
                            name="qty"
                            value={shipQty}
                            onChange={handleChange}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={2}>Packing List Number</Label>
                    <Col sm={10}>
                        <Input
                            type="text"
                            name="packListNumber"
                            value={packListNumber}
                            onChange={handleChange}
                        />
                    </Col>
                </FormGroup>
                <FileUpload
                    accept="*.*"
                    label="Shipping documents (as needed)"
                    onFilesTooLarge={alertFilesTooLarge}
                    onFilesUpdated={filesUpdated}
                    maxFileSizeInBytes={MAX_FILE_SIZE_MB*1000*1000}
                    multiple
                />
                <div>
                    <Button onClick={submitNewShipment} disabled={!(shipQty > 0) || !packListNumber}>Submit</Button>
                    <Button onClick={props.onCancel}>Cancel</Button>
                </div>
            </Form>
            {errorMessage &&
                <div>
                    <Alert color="danger" toggle={() => setErrorMessage(null)}>
                        Error occurred! {errorMessage}
                    </Alert>
                </div>
            }
        </Fragment>
    );
}

export function ReceiveShipmentForm(props) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [receivedQty, setReceivedQty] = useState(0);

    async function submitReceivedShipment() {
        props.onSubmit(receivedQty);
    }

    function handleChange(ev) {
        ev.preventDefault();
        let { name, value } = ev.target;
        if (name === 'qty') setReceivedQty(value);
        else setErrorMessage("Unsupported input received.");
    }

    return (
        <Fragment>
            <h4>{`Purchase Order ${props.purchaseOrder.poNumber} (${props.purchaseOrder.partNumber})`}</h4>
            <p>
                {`Week ${props.scheduleEntry.weekData.weekInfo.weekNumber} (${getSimpleDateString(props.scheduleEntry.weekData.releaseDate)}) - `}
                {props.scheduleEntry.shipments && props.scheduleEntry.shipments.length > 0 ? (
                    <Fragment>
                        {props.scheduleEntry.weekData.qtyRequired} required
                        <strong>
                            {` (${props.scheduleEntry.weekData.qtyRequired - props.scheduleEntry.shipments.reduce((accum, cur) => accum + cur.shipment.shipQty, 0)} remaining)`}
                        </strong>
                    </Fragment>
                ) : (
                    <strong>
                        {props.scheduleEntry.weekData.qtyRequired} required
                    </strong>
                )}
            </p>
            <Form>
                <FormGroup row>
                    <Label sm={2}>Packing list number</Label>
                    <Col sm={10}>
                        <Input type="text" disabled value={props.shipment.shipment.packingListNumber} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={2}>Qty shipped</Label>
                    <Col sm={10}>
                        <Input type="number" disabled value={props.shipment.shipment.shipQty} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={2}>Qty received</Label>
                    <Col sm={10}>
                        <Input
                            type="number"
                            name="qty"
                            value={receivedQty}
                            onChange={handleChange}
                        />
                    </Col>
                </FormGroup>
                <div>
                    <Button onClick={submitReceivedShipment} disabled={!(receivedQty > 0)}>Submit</Button>
                    <Button onClick={props.onCancel}>Cancel</Button>
                </div>
            </Form>
            {errorMessage &&
                <div>
                    <Alert color="danger" toggle={() => setErrorMessage(null)}>
                        Error occurred! {errorMessage}
                    </Alert>
                </div>
            }
        </Fragment>
    );
}
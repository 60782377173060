import React, { Fragment, useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { getFetchHeadersAsync } from '../lib/util';
import getPermissions from './api-authorization/Permissions.js'
import SupplierManager from './SupplierManager';

export default function MySupplierManager() {
    const [supplier, setSupplier] = useState(null);
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState(null);

    useEffect(() => {
        if (!permissions) getPerms();
        if (!supplier) populateSupplierData();
    });

    async function getPerms() {
        const perms = await getPermissions();
        setPermissions(perms);
    }

    async function populateSupplierData() {
        setSupplier({});
        const response = await fetch('api/myinfo/supplier', await getFetchHeadersAsync());
        const data = await response.json();
        setSupplier(data.result);
        setLoading(false);
    }

    return loading ? <Label>Loading...</Label> : (
        <Fragment>
            <h2>{supplier.name}</h2>
            <SupplierManager onSupplierSaved={populateSupplierData} supplier={supplier} permissions={permissions} />
        </Fragment>
    );
}
import React, { Fragment, useState } from 'react';
import { Alert, Button, Col, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import UsersManager from './UsersManager';
import SupplierPOsManager from './SupplierPOsManager';
import ForecastManager from './ForecastManager';
import { getFetchHeadersAsync } from '../lib/util';
import ShipmentsManager from './ShipmentsManager';

export default function SupplierManager(props) {
    const [activeTab, setActiveTab] = useState(null);
    const [loading, setLoading] = useState(false);

    const [currentSupplier, setCurrentSupplier] = useState(null);
    const [supplierDraft, setSupplierDraft] = useState(null);
    const [supplierModified, setSupplierModified] = useState(false);
    const [usersModified, setUsersModified] = useState(false);
    const [purchaseOrdersModified, setPurchaseOrdersModified] = useState(false);
    const [forecastModified, setForecastModified] = useState(false);
    //const [scheduleEditing, setScheduleEditing] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    function setTab(tab) {
        if (activeTab !== tab) setActiveTab(tab);
    }

    function createSupplierDraft(original) {
        setSupplierDraft({
            id: original?.id ?? '',
            name: original?.name ?? '',
            supplierCode: original?.supplierCode ?? '',
            primaryContact: original?.primaryContact ?? '',
            phone: original?.phone ?? '',
            email: original?.email ?? '',
            isEnabled: original?.isEnabled ?? true,
        });
        setSupplierModified(false);
    }

    function supplierHasBeenModified(draft) {
        let modified = false;
        let original = props.supplier;
        for (var key in draft) {
            if (!(key in original) || draft[key] !== original[key]) {
                modified = true;
            }
        }
        return modified;
    }

    function supplierDraftChanged(draft) {
        let modified = supplierHasBeenModified(draft);
        setSupplierDraft(draft);
        setSupplierModified(modified);
        // Notify parent about whether the supplier has been modified
        props.onChange && props.onChange(draft, modified);
    }

    function usersChanged(draft, modified) {
        setUsersModified(modified);
    }

    function purchaseOrdersChanged(draft, modified) {
        setPurchaseOrdersModified(modified);
    }

    function forecastChanged(draft, modified) {
        setForecastModified(modified);
    }

    async function saveSupplierChangesAsync() {
        setLoading(true);
        var method = props.supplier.id === -1 ? 'PUT' : 'POST';

        let ok = false;
        try {
            const response = await fetch('api/suppliers', await getFetchHeadersAsync(method, supplierDraft));
            const data = await response.json();
            if (data.errors || data.error) throw data.error || data.title;

            props.onSupplierSaved && props.onSupplierSaved();
            ok = true;
        }
        catch (error) {
            setErrorMessage(error?.message || error);
        }
        setLoading(false);
        return ok;
    }

    function cancelSupplierChanges() {
        createSupplierDraft(props.supplier);
        props.onCancel && props.onCancel();
    }

    function resetSupplierChanges() {
        createSupplierDraft(props.supplier);
        props.onReset && props.onReset();
        props.onChange && props.onChange(supplierDraft, false);
    }

    function tabsDisabled() {
        return props.supplier?.id < 0 || supplierModified || usersModified || purchaseOrdersModified || forecastModified;
    }

    function getActiveTab() {
        if (activeTab) return activeTab;
        if (!props.params) return '1';

        if (props.params.showShipments || props.params.showShipmentsForPurchaseOrder)
            return '5';
        if (props.params.showForecast)
            return '4';
        if (props.params.showPurchaseOrders)
            return '3';
        if (props.params.showUsers)
            return '2';
        return '1';
    }

    if (props.supplier !== currentSupplier) {
        setCurrentSupplier(props.supplier);
        createSupplierDraft(props.supplier);
    }
    if (props.selectedTabId) {
        setActiveTab(props.selectedTabId);
    }


    return (loading ? <h3>Loading...</h3> : (
        <div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: getActiveTab() === '1' })}
                        disabled={tabsDisabled()}
                        onClick={() => { setTab('1'); }}>
                        Supplier Details
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: getActiveTab() === '2' })}
                        disabled={tabsDisabled()}
                        onClick={() => { setTab('2'); }}>
                        Users
                    </NavLink>
                </NavItem>
                {props.supplier.isRecipient || (
                    <Fragment>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: getActiveTab() === '3' })}
                                disabled={tabsDisabled()}
                                onClick={() => { setTab('3'); }}>
                                Purchase Orders
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: getActiveTab() === '4' })}
                                disabled={tabsDisabled()}
                                onClick={() => { setTab('4'); }}>
                                Forecast
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: getActiveTab() === '5' })}
                                disabled={tabsDisabled()}
                                onClick={() => { setTab('5'); }}>
                                Shipments
                            </NavLink>
                        </NavItem>
                    </Fragment>
                )}
            </Nav>
            <TabContent activeTab={getActiveTab()}>
                {getActiveTab() === "1" && (
                    <TabPane tabId="1">
                        <SupplierEditForm
                            params={props.params}
                            supplier={props.supplier}
                            supplierDraft={supplierDraft}
                            isModified={supplierModified}
                            onChange={supplierDraftChanged}
                            onSaveItemAsync={saveSupplierChangesAsync}
                            onCancelEdit={cancelSupplierChanges}
                            onResetForm={resetSupplierChanges}
                            permissions={props.permissions}
                        />
                    </TabPane>
                )}
                {getActiveTab() === "2" && (
                    <TabPane tabId="2">
                        <UsersManager
                            params={props.params}
                            supplier={props.supplier}
                            onChange={usersChanged}
                            permissions={props.permissions} />
                    </TabPane>
                )}
                {getActiveTab() === "3" && (
                    <TabPane tabId="3">
                        <SupplierPOsManager
                            params={props.params}
                            supplier={props.supplier}
                            onChange={purchaseOrdersChanged}
                            permissions={props.permissions} />
                    </TabPane>
                )}
                {getActiveTab() === "4" && (
                    <TabPane tabId="4">
                        <ForecastManager
                            params={props.params}
                            supplier={props.supplier}
                            onChange={forecastChanged}
                            permissions={props.permissions} />
                    </TabPane>
                )}
                {getActiveTab() === "5" && (
                    <TabPane tabId="5">
                        <ShipmentsManager
                            params={props.params}
                            supplier={props.supplier}
                            onChange={forecastChanged}
                            permissions={props.permissions} />
                    </TabPane>
                )}
            </TabContent>
            {errorMessage && (
                <div>
                    <Alert color="danger" toggle={() => setErrorMessage(null)}>
                        Error occurred! {errorMessage}
                    </Alert>
                </div>
            )}
        </div>
    ));
}

export function SupplierEditForm(props) {
    const [saveSuccess, setSaveSuccess] = useState(false);

    function handleChange(event) {
        event.preventDefault();
        const { name, value, type } = event.target;
        let isCheckbox = type === "checkbox";
        // create a copy of the draft state and send notification
        let draft = { ...props.supplierDraft };
        draft[name] = isCheckbox ? event.target.checked : value;
        props.onChange(draft);
    }

    async function saveItem() {
        const success = await props.onSaveItemAsync();
        if (success) {
            setSaveSuccess(true);
        }
    }

    function isModified(key) {
        return props.supplier[key]?.toString() !== props.supplierDraft[key]?.toString();
    }


    return (
        <Form>
            <FormGroup row>
                <Label sm={2}>Name</Label>
                <Col sm={10}>
                    <Input
                        type="name"
                        name="name"
                        placeholder="Supplier"
                        value={props.supplierDraft.name}
                        onChange={handleChange}
                        disabled={!props.permissions?.suppliersAdmin}
                        className={isModified("name") ? 'modified' : ''} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>Supplier Code</Label>
                <Col sm={10}>
                    <Input
                        type="name"
                        name="supplierCode"
                        placeholder="12345"
                        value={props.supplierDraft.supplierCode}
                        onChange={handleChange}
                        disabled={!props.permissions?.suppliersAdmin}
                        className={isModified("supplierCode") ? 'modified' : ''} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>Primary Contact</Label>
                <Col sm={10}>
                    <Input
                        type="name"
                        name="primaryContact"
                        placeholder="Primary Contact"
                        value={props.supplierDraft.primaryContact}
                        onChange={handleChange}
                        disabled={!props.permissions?.siteAdmin}
                        className={isModified("primaryContact") ? 'modified' : ''} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>Phone</Label>
                <Col sm={10}>
                    <Input
                        type="tel"
                        name="phone"
                        placeholder="+1-555-123-4567"
                        value={props.supplierDraft.phone}
                        onChange={handleChange}
                        disabled={!props.permissions?.siteAdmin}
                        className={isModified("phone") ? 'modified' : ''} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>Email</Label>
                <Col sm={10}>
                    <Input
                        type="email"
                        name="email"
                        placeholder="email@company.com"
                        value={props.supplierDraft.email}
                        onChange={handleChange}
                        disabled={!props.permissions?.siteAdmin}
                        className={isModified("email") ? 'modified' : ''} />
                </Col>
            </FormGroup>
            {props.permissions?.siteAdmin &&
                <Fragment>
                    {props.permissions?.suppliersAdmin &&
                        <FormGroup row>
                            <Label sm={2}></Label>
                            <Col sm={10}>
                                <Label>
                                    <Input
                                        type="checkbox"
                                        name="isEnabled"
                                        checked={props.supplierDraft.isEnabled}
                                        onChange={handleChange}
                                        className={isModified("isEnabled") ? 'modified' : ''} />
                                    Enabled
                                </Label>
                            </Col>
                        </FormGroup>
                    }
                    <div>
                        <Button onClick={saveItem} disabled={!props.isModified}>Save</Button>
                        <Button onClick={props.onResetForm} disabled={!props.isModified}>Reset</Button>
                        <Button onClick={props.onCancelEdit}>Cancel</Button>
                    </div>
                </Fragment>
            }
            {saveSuccess &&
                <div>
                    <Alert color="success">
                        Saved successfully!
                    </Alert>
                </div>
            }
        </Form>
    );
}

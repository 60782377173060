import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Table } from 'reactstrap';
import { useHistory, Link } from "react-router-dom";
import authService from './api-authorization/AuthorizeService'
import getPermissions from './api-authorization/Permissions.js'
import SupplierManager from './SupplierManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'
import { compareStrings } from '../lib/util';

export default function SuppliersManager(props) {
    const history = useHistory();

    const [params, setParams] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [suppliers, setSuppliers] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [selectedSupplierModified, setSelectedSupplierModified] = useState(false);
    const [permissions, setPermissions] = useState(null);

    useEffect(() => {
        if (!permissions) getPerms();
        if (!params) setParams(getParams(window.location.href));
        if (params && !suppliers) populateSuppliersData(params.supplierId);

        return history.listen((location, action) => {
            let newParams = getParams(location.pathname);
            setParams(newParams);
            if (!newParams.supplierId)
                setSelectedSupplier(null);
            populateSuppliersData(newParams?.supplierId);
        });
    }, [history, permissions, suppliers, params]);

    async function getPerms() {
        const perms = await getPermissions();
        setPermissions(perms);
    }

    function getParams(path) {
        const regex = /\/suppliers(?:\/(?<supplierId>\d+)(?:\/(?:(?<showUsers>users)|(?<showForecast>forecast)|(?<showShipments>shipments)|(?<showPurchaseOrders>purchaseorders(?:\/(?<purchaseOrderId>\d+)(?<showShipmentsForPurchaseOrder>\/shipments)?)?)))?)?$/i;
        const match = path.match(regex);
        if (!match) return null;
        return match.groups;
    }

    function updateSelectedSupplier(suppliers, supplierId) {
        const paramsSupplier = suppliers?.find(s => s.id === parseInt(supplierId));
        setSelectedSupplier(paramsSupplier);
    }

    async function populateSuppliersData(forceSupplierId) {
        setSuppliers([]);
        const token = await authService.getAccessToken();
        let newSuppliers = [];
        try {
            const response = await fetch('api/suppliers' + (forceSupplierId ? '/' + forceSupplierId : ''), {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            if (data.errors || data.error) throw data.error || data.title;
            newSuppliers = data.result?.sort((r1, r2) => compareStrings(r1.name, r2.name));
            setSuppliers(newSuppliers);

            const params = getParams(window.location.href);
            if (params.supplierId)
                updateSelectedSupplier(newSuppliers, params.supplierId);
        }
        catch (error) {
            setErrorMessage(error?.message || error);
        }

        setLoading(false);
        return newSuppliers;
    }

    async function deleteSupplier(supplier) {
        setLoading(true);
        const token = await authService.getAccessToken();

        let ok = false;
        try {
            const response = await fetch('api/suppliers', {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                method: 'DELETE',
                body: JSON.stringify(supplier)
            });
            const data = await response.json();
            if (data.errors || data.error) throw data.error || data.title;

            await populateSuppliersData();
            ok = true;
        }
        catch (error) {
            setErrorMessage(error?.message || error);
        }
        setLoading(false);
        return ok;
    }

    function selectSupplier(supplier, newSuppliers) {
        if (!newSuppliers) newSuppliers = suppliers;
        if (typeof (supplier) === 'number')
            supplier = newSuppliers?.find(s => s.id === supplier);
        setSelectedSupplier(supplier);
        setSelectedSupplierModified(false);
    }

    function supplierDraftChanged(supplierDraft, modified) {
        setSelectedSupplierModified(modified);
    }

    async function supplierSaved() {
        const id = selectedSupplier.id;
        var newSuppliers = await populateSuppliersData();
        selectSupplier(id, newSuppliers);
    }

    function addNewSupplier() {
        let newSupplier = { id: -1 };
        selectSupplier(newSupplier);
    }

    function canViewSuppliers() {
        return (permissions?.suppliersAdmin || permissions?.isRecipient);
    }

    return (
        <div>
            <h2 id="tableLabel">{selectedSupplier
                ? (<Fragment>
                    {(selectedSupplier?.name ?? 'New Supplier') + ' '}
                    {canViewSuppliers() && <Link className="btn btn-secondary" to="/suppliers" onClick={() => selectSupplier(null)} disabled={selectedSupplierModified}>Back</Link>}
                </Fragment>)
                : "Suppliers"
            }</h2>
            {loading ? <p><em>Loading...</em></p> : renderContents()}
        </div>
    );

    function renderContents() {
        return (
            <Fragment>
                {selectedSupplier ? (
                    <SupplierManager
                        supplier={selectedSupplier}
                        params={params}
                        onSupplierSaved={supplierSaved}
                        onChange={supplierDraftChanged}
                        onCancel={() => selectSupplier(null)}
                        permissions={permissions}
                    />
                    ) : (canViewSuppliers() && renderSuppliersTable(suppliers))
                }
                {errorMessage &&
                    <div>
                        <Alert color="danger" toggle={() => setErrorMessage(null)}>
                            Error occurred! {errorMessage}
                        </Alert>
                    </div>
                }
            </Fragment>
        );
    }

    function renderSuppliersTable(suppliers) {
        return (
            <Fragment>
                <Table hover className='table table-sm' aria-labelledby="tableLabel">
                    <thead>
                        <tr>
                            {/*<th>ID</th>*/}
                            <th>Name</th>
                            <th>Supp.Code</th>
                            <th>Created</th>
                            <th>Enabled</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {suppliers?.map(s =>
                            <SupplierRow
                                key={s.id}
                                supplier={s}
                                enabled={!selectedSupplierModified}
                                onSelect={selectSupplier}
                                onDelete={deleteSupplier}
                                permissions={permissions}
                            />
                        )}
                    </tbody>
                </Table>
                <Button onClick={addNewSupplier}>Add Supplier</Button>
            </Fragment>
        );
    }
}

export function SupplierRow(props) {
    return (
        <tr className={props.supplier.isEnabled && props.enabled ? "" : "table-disabled"}>
            {/*<th className="align-items-center" scope="row">{props.supplier.id}</th>*/}
            <td>{props.supplier.name}</td>
            <td>{props.supplier.supplierCode}</td>
            <td>{format(new Date(props.supplier.createdDate), 'yyyy-MM-dd')}</td>
            <td>{props.supplier.isEnabled && <FontAwesomeIcon icon={faCheck} title="Enabled" />}</td>
            <td className="td-actions">
                {props.enabled && (
                    <Fragment>
                        {!props.permissions.suppliersAdmin && (
                            <Button color="link" title="View" onClick={() => props.onSelect && props.onSelect(props.supplier)}>
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        )}
                        {props.permissions.suppliersAdmin && (
                            <Fragment>
                                <Link color="link" title="Edit" to={"/suppliers/" + props.supplier.id} onClick={() => props.onSelect && props.onSelect(props.supplier)}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </Link>
                                <Button color="link" title="Delete" onClick={() => props.onDelete && props.onDelete(props.supplier)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </td>
        </tr>
    );
}
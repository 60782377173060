import React, { Fragment, useEffect, useState } from 'react';
import {
    Alert, Button, Input, Table
} from 'reactstrap';
//import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'
import { addDays, getWeekInfo, getFetchHeadersAsync, getTimestamp } from '../lib/util'

export default function ForecastManager(props) {
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [editing, setEditing] = useState(false);
    const [firmLocked, setFirmLocked] = useState(true);
    const [weeks, setWeeks] = useState([]);
    const [forecast, setForecast] = useState(null);
    const [forecastDraft, setForecastDraft] = useState(null);
    const [forecastModified, setForecastModified] = useState(false);
    const [forecastFirmModified, setForecastFirmModified] = useState(false);
    const [miscDetailsModified, setMiscDetailsModified] = useState(false);
    const [showMiscDetails, setShowMiscDetails] = useState(false);

    useEffect(() => {
        if (!forecast && props.supplier.id >= 0) getForecastAsync();
    });

    function updateForecast(forecast) {
        setForecast(forecast);
        initForecastDraft(forecast);
    }

    function cancelForecastChanges() {
        initForecastDraft(forecast);
    }

    function toggleEditMode() {
        if (editing)
            setFirmLocked(true);
        setEditing(!editing);
    }

    function initForecastDraft(forecast) {
        let date = addDays(new Date(), -7);
        let newWeeks = [];
        for (let i = 0; i < 9; i++) {
            newWeeks.push(getWeekInfo(date));
            date = addDays(date, 7);
        }
        setWeeks(newWeeks);

        const draft = forecast.map(fc => ({
            purchaseOrder: { ...fc.purchaseOrder },
            stockData: !!fc.stockData
                ? { ...fc.stockData }
                : { averageWeeklyUsage: 0, inStock: 0 },
            weeklyData: newWeeks
                .map(week => ({ week, data: fc.weeklyData?.find(d => new Date(d.releaseDate).getTime() === week.dateFrom.getTime()) }))
                .map(wd => wd.data ? { ...wd.data } : {
                    releaseDate: wd.week.dateFrom,
                    qtyRequired: 0
                })
        }));
        setForecastDraft(draft);
        setForecastModified(false);
        setForecastFirmModified(false);
        setMiscDetailsModified(false);
        props.onChange && props.onChange(draft, false);
    }

    function itemHasBeenModified(original, draft) {
        if (!original && !!draft) return true; // created a new item
        // modifying item
        for (var key in draft) {
            if (!(key in original)) return true;
            let draftItem = draft[key];
            let originalItem = original[key];
            let isDate = typeof (draftItem).getTime === 'function' || typeof originalItem.getTime === 'function';
            if (!(key in original) || isDate
                ? new Date(originalItem).getTime() !== new Date(draftItem).getTime()
                : draft[key]?.toString() !== original[key]?.toString()) {
                return true;
            }
        }
        return false;
    }

    function updateModified(draft) {
        let modified = false;
        let miscModified = false;
        let firmModified = false;
        for (let i = 0; i < draft.length; i++) {
            if (itemHasBeenModified(forecast[i].purchaseOrder, draft[i].purchaseOrder))
                miscModified = true;
            if (itemHasBeenModified(forecast[i].stockData, draft[i].stockData))
                miscModified = true;
            // start at index 1 since we're skipping the previous week
            for (let w = 1; w < draft[i].weeklyData.length; w++) {
                if (!forecast[i].weeklyData) {
                    modified = true; continue;
                }
                let item = draft[i].weeklyData[w];
                let matching = forecast[i].weeklyData.find(w => w.releaseDate
                    && new Date(w.releaseDate).getTime() === new Date(item.releaseDate).getTime());
                if (!matching || itemHasBeenModified(matching, item)) {
                    modified = true;
                    if (w < 5)
                        firmModified = true;
                }
            }
        }
        setMiscDetailsModified(miscModified);
        setForecastModified(modified);
        setForecastFirmModified(firmModified);
        return modified || firmModified || miscModified;
    }

    function isModified() {
        return forecastModified || forecastFirmModified || miscDetailsModified;
    }

    function updateForecastPurchaseOrder(originalForecast, draft) {
        let newDraft = null;
        setForecastDraft(prev => {
            newDraft = prev.map(item => item.purchaseOrder.id === originalForecast.purchaseOrder.id
                ? { ...item, purchaseOrder: draft }
                : item);
            let modified = updateModified(newDraft);
            props.onChange && props.onChange(newDraft, modified);
            return newDraft;
        });
    }

    function updateForecastStockData(originalForecast, draft) {
        setForecastDraft(prev => {
            let newDraft = prev.map(item => item.purchaseOrder.id === originalForecast.purchaseOrder.id
                ? { ...item, stockData: draft }
                : item);
            let modified = updateModified(newDraft);
            props.onChange && props.onChange(newDraft, modified);
            return newDraft;
        });
    }

    function updateForecastWeeklyData(originalForecast, draft) {
        setForecastDraft(prev => {
            let newDraft = prev.map(item => item.purchaseOrder.id === originalForecast.purchaseOrder.id
                ? { ...item, weeklyData: draft }
                : item);
            // item.weeklyData?.map(wd => wd.releaseDate === weekData.releaseDate ? { ...wd } : wd)
            let modified = updateModified(newDraft);
            props.onChange && props.onChange(newDraft, modified);
            return newDraft;
        });
    }

    async function getForecastAsync() {
        setForecast([]);
        try {
            const forecastResponse = await fetch(`api/suppliers/${props.supplier.id}/forecast`, await getFetchHeadersAsync());
            const forecastData = await forecastResponse.json();
            if (forecastData.errors || forecastData.error)
                throw forecastData.error || forecastData.title;

            updateForecast(forecastData.result);
        }
        catch (error) {
            setErrorMessage(error?.message || error);
        }
        setLoading(false);
    }

    async function saveForecastAsync(draft) {
        try {
            const response = await fetch(`api/suppliers/${props.supplier.id}/forecast`, await getFetchHeadersAsync('POST', draft));
            const data = await response.json();
            if (data.errors || data.error) throw data.error || data.title;

            cancelForecastChanges();
            await getForecastAsync();
            props.onPurchaseOrderSaved && props.onPurchaseOrderSaved();
            setEditing(false);
            return true;
        }
        catch (error) {
            setErrorMessage(error?.message || error);
            return false;
        }
    }

    return loading ? <p>Loading...</p> : (
        <Fragment>
            <Table hover className='table table-sm'>
                <thead>
                    <tr>
                        <th>PO</th>
                        {props.permissions.suppliersAdmin && (
                            <th>Article</th>
                        )}
                        <th>Part</th>
                        {showMiscDetails && (
                            <Fragment>
                                <th>Pallet Qty</th>
                                <th>Weekly Avg</th>
                                {props.permissions.suppliersAdmin &&
                                    <Fragment>
                                        <th>In Stock</th>
                                        <th>Weeks</th>
                                    </Fragment>
                                }
                            </Fragment>
                        )}
                        {weeks?.map(week => (
                            <th key={week.dateFrom} className="col-week">
                                <h4>{week.weekNumber}</h4>
                                {format(week.dateFrom, 'MMM dd')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {forecast?.map(f => (
                        <ForecastRow
                            key={f.purchaseOrder.id}
                            forecast={f}
                            forecastDraft={forecastDraft?.find(fd => fd.purchaseOrder?.id === f.purchaseOrder.id)}
                            weeks={weeks}
                            permissions={props.permissions}
                            lastReadDate={props.lastReadDate}
                            editing={editing}
                            firmLocked={firmLocked}
                            showMiscDetails={showMiscDetails}
                            onPurchaseOrderChanged={updateForecastPurchaseOrder}
                            onStockDataChanged={updateForecastStockData}
                            onWeeklyDataChanged={updateForecastWeeklyData}
                        />
                    ))}
                </tbody>
            </Table>
            {!miscDetailsModified && (
                <Button onClick={() => setShowMiscDetails(!showMiscDetails)}>{showMiscDetails ? 'Hide' : 'Show'} misc data</Button>
            )}
            {props.permissions.suppliersAdmin && editing && !forecastFirmModified && (
                <Button onClick={() => setFirmLocked(!firmLocked)}>{firmLocked ? 'Unlock firm weeks' : 'Lock firm weeks'}</Button>
            )}
            {props.permissions.suppliersAdmin && !(editing && isModified()) && (
                <Button onClick={toggleEditMode}>{editing ? 'Cancel edit mode' : 'Edit'}</Button>
            )}
            {props.permissions.suppliersAdmin && editing && isModified() && (
                <Fragment>
                    <Button onClick={() => saveForecastAsync(forecastDraft)}>Save Changes</Button>
                    <Button onClick={cancelForecastChanges}>Reset</Button>
                </Fragment>
            )}
            {errorMessage && (
                <div>
                    <Alert color="danger" toggle={() => setErrorMessage(null)}>
                        Error occurred! {errorMessage}
                    </Alert>
                </div>
            )}
        </Fragment>
    );
}

export function ForecastRow(props) {
    function handlePurchaseOrderValueChange(event, name) {
        event.preventDefault();
        let { value, type } = event.target;
        let isCheckbox = type === "checkbox";
        //let isDropdown = type === "button" && !!textContent;
        let draft = { ...props.forecastDraft.purchaseOrder };
        if (isCheckbox) value = event.target.checked;
        if (draft[name] === value) return;
        draft[name] = value;
        props.onPurchaseOrderChanged && props.onPurchaseOrderChanged(props.forecast, draft);
    }

    function handleStockDataValueChange(event, name) {
        let { value, type } = event.target;
        let isCheckbox = type === "checkbox";
        //let isDropdown = type === "button" && !!textContent;
        let draft = { ...props.forecastDraft.stockData };
        if (isCheckbox) value = event.target.checked;
        if (draft[name] === value) return;
        draft[name] = value;
        props.onStockDataChanged && props.onStockDataChanged(props.forecast, draft);
    }

    function handleWeeklyDataValueChange(event, releaseDate) {
        let { value, type } = event.target;
        if (type === "number") {
            let test = /^[0-9.,]*$/.test(value);
            if (!test) {
                event.preventDefault();
                return;
            }
            value = parseFloat(value);
        }
        else if (type === "checkbox") value = event.target.checked;

        let draft = props.forecastDraft.weeklyData.map(item => new Date(item.releaseDate).getTime() === releaseDate.getTime()
            ? { releaseDate, qtyRequired: value }
            : item
        );
        props.onWeeklyDataChanged && props.onWeeklyDataChanged(props.forecast, draft);
    }

    function isModified(key1, key2) {
        if (!props.forecast[key1]) return true;
        if (key1 === "weeklyData")
            return parseInt(props.forecast[key1].find(w => { return new Date(w.releaseDate).getTime() === key2.getTime() })?.qtyRequired)
                !== parseInt(props.forecastDraft[key1].find(w => { return new Date(w.releaseDate).getTime() === key2.getTime() })?.qtyRequired)
        else {
            return props.forecast[key1][key2].toString() !== props.forecastDraft[key1][key2].toString();
        }
    }

    return (
        <Fragment>
            <tr className="row-forecast">
                <th className="align-items-center" scope="row">
                    {props.forecast.purchaseOrder.poNumber}
                </th>
                {props.permissions.suppliersAdmin && (
                    <td>{props.forecast.purchaseOrder.articleNumber}</td>
                )}
                <td>{props.forecast.purchaseOrder.partNumber}</td>
                {props.showMiscDetails && (
                    <Fragment>
                        {props.editing ? (
                            <Fragment>
                                <td>
                                    <Input
                                        type="number"
                                        name={`po-${props.forecast.purchaseOrder.id}-palletQty`}
                                        value={props.forecastDraft.purchaseOrder.palletQty}
                                        onChange={ev => handlePurchaseOrderValueChange(ev, "palletQty")}
                                        className={'no-spin' + (isModified("purchaseOrder", "palletQty") ? ' modified' : '')} />
                                </td>
                                <td>
                                    <Input
                                        type="number"
                                        name={`sd-${props.forecast.purchaseOrder.id}-avgWeekly`}
                                        value={props.forecastDraft.stockData?.averageWeeklyUsage}
                                        onChange={ev => handleStockDataValueChange(ev, "averageWeeklyUsage")}
                                        className={'no-spin' + (isModified("stockData", "averageWeeklyUsage") ? ' modified' : '')} />
                                    {props.stockData && props.lastReadDate < props.stockData.updatedDate && (
                                        <FontAwesomeIcon icon={faExclamationCircle} title="Updated" />
                                    )}
                                </td>
                                <td>
                                    <Input
                                        type="number"
                                        name={`sd-${props.forecast.purchaseOrder.id}-inStock`}
                                        value={props.forecastDraft.stockData?.inStock}
                                        onChange={ev => handleStockDataValueChange(ev, "inStock")}
                                        className={'no-spin' + (isModified("stockData", "inStock") ? ' modified' : '')} />
                                    {props.stockData && props.lastReadDate < props.stockData.updatedDate && (
                                        <FontAwesomeIcon icon={faExclamationCircle} title="Updated" />
                                    )}
                                </td>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <td>{props.forecastDraft.purchaseOrder.palletQty ?? 0}</td>
                                <td>{props.forecastDraft.stockData?.averageWeeklyUsage ?? 0}</td>
                                {props.permissions.suppliersAdmin &&
                                    <td>{props.forecastDraft.stockData?.inStock ?? 0}</td>
                                }
                            </Fragment>
                        )}
                        {props.permissions.suppliersAdmin && (
                            <td>{((props.forecastDraft.stockData && props.forecastDraft.stockData.averageWeeklyUsage
                                && props.forecastDraft.stockData.inStock / props.forecastDraft.stockData.averageWeeklyUsage) || 0).toFixed(1)}</td>
                        )}
                    </Fragment>
                )}
                {props.weeks?.map(week => ({
                    week,
                    past: props.weeks[0] === week,
                    firm: props.weeks.slice(0, 5).includes(week),
                    data: props.forecastDraft.weeklyData?.find(d => new Date(d.releaseDate).getTime() === week.dateFrom.getTime()) ?? {}
                })).map(wd => (
                    <td className="bordered" key={JSON.stringify(wd.week)}>
                        {!props.editing || wd.past || (wd.firm && props.firmLocked) ? wd.data?.qtyRequired ?? 0 : (
                            <Fragment>
                                <Input
                                    type="number"
                                    name={`wd-${props.forecast.purchaseOrder.id}-${format(wd.week.dateFrom, 'yyMMdd')}`}
                                    value={wd.data?.qtyRequired}
                                    onChange={ev => handleWeeklyDataValueChange(ev, wd.week.dateFrom)}
                                    className={'no-spin' + (isModified("weeklyData", wd.week.dateFrom) ? ' modified' : '')} />
                                {wd.data && props.lastReadDate < wd.data.updatedDate && (
                                    <FontAwesomeIcon icon={faExclamationCircle} title="Updated" />
                                )}
                            </Fragment>
                        )}
                    </td>
                ))}
            </tr>
        </Fragment>
    );
}
import React, { Fragment, useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import getPermissions from './api-authorization/Permissions.js'
import { getFetchHeadersAsync } from '../lib/util'
import ForecastManager from './ForecastManager'

export default function MyForecast() {
    const [supplier, setSupplier] = useState(null);
    const [lastReadDate, setLastReadDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState(null);

    useEffect(() => {
        if (!permissions) getPerms();
        if (!supplier) populateSupplierData();
        if (!lastReadDate) getLastReadDate();
    });

    async function getPerms() {
        const perms = await getPermissions();
        setPermissions(perms);
    }

    async function populateSupplierData() {
        setSupplier({});
        const supplierResponse = await fetch('api/myinfo/supplier', await getFetchHeadersAsync());
        const supplierData = await supplierResponse.json();
        setSupplier(supplierData.result);
        setLoading(false);
    }

    async function getLastReadDate() {
        setLastReadDate(new Date());
        const dateResponse = await fetch('api/myinfo/lastread', await getFetchHeadersAsync());
        const dateData = await dateResponse.json();
        setLastReadDate(dateData.result);
        setLoading(false);
    }

    return loading ? <Label>Loading...</Label> : (
        <Fragment>
            <h2>{supplier.name}</h2>
            <ForecastManager supplier={supplier} lastReadDate={lastReadDate} permissions={permissions} />
        </Fragment>
    );
}
import { format } from 'date-fns';
import authService from '../components/api-authorization/AuthorizeService';

export function weekNumberFromDate(dt) {
    dt = dt || new Date();
    let tdt = new Date(dt.valueOf());
    const dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    const firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt) / 604800000);
}

export function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

export function getDate(d) {
    return new Date(d).setHours(0, 0, 0, 0);
}
export function getTimestamp(d) {
    return getDate(d).getTime();
}

// Returns the four-digit year corresponding to the ISO week of the date.
export function getWeekYear(date) {
    date = new Date(date.valueOf());
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
}

export function addDays(date, days) {
    date = new Date(date.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export function getWeekInfo(date) {
    date = new Date(new Date(date).setHours(0, 0, 0, 0));
    const weekNumber = weekNumberFromDate(date);
    const year = getWeekYear(date);
    const dateFrom = getMonday(date);
    //const weekStart = dateFromWeekNumber(weekNumber, year);
    return { weekNumber, dateFrom, dateTo: addDays(dateFrom, 6), year };
}

export function getSimpleDateString(date) {
    if (!date) return date;
    if (typeof date === 'string')
        date = new Date(date);
    return format(date, 'MMM dd');
}

export function objectToArray(obj) {
    if (!obj) return null;
    return Object.keys(obj).map(k => obj[k]);
}

export function compareStrings(s1, s2) {
    if (!s1) return 1;
    if (!s2) return -1;
    s1 = s1.toUpperCase();
    s2 = s2.toUpperCase();
    if (s1 < s2) return -1;
    if (s1 > s2) return 1;
    return 0;
}

export async function getFetchHeadersAsync(method, body) {
    const token = await authService.getAccessToken();
    const headers = {
        headers: !token ? {} : {
            'Authorization': `Bearer ${token}`,
        }
    };
    if (!!method) headers.method = method;
    if (!!body) {
        headers.body = JSON.stringify(body);
        headers.headers['Content-Type'] = 'application/json';
    }
    return headers;
}

export function xhrRequest(opts) {
    const { url, headers, body, files, onProgress } = opts;
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open(headers.method ?? 'POST', url);
        for (var headerKey in headers.headers)
            xhr.setRequestHeader(headerKey, headers.headers[headerKey]);
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(xhr);
            } else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
        };
        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };
        xhr.onprogress = onProgress;

        const formData = new FormData();
        if (!!body)
            for (var bodyKey in body)
                formData.append(bodyKey, body[bodyKey]);
        if (!!files) {
            if (typeof files.length === 'number') {
                files.forEach(f => formData.append('files', f));
            }
            else if (typeof files === 'object') {
                for (var fileKey in files)
                    formData.append('files', files[fileKey]);
            }
        }
        if (!!files || !!body) {
            for (var formKey of formData.keys()) {
                console.log(JSON.stringify(formKey));
            }
        }
        xhr.send(formData);
    });
}

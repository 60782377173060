import authService from './AuthorizeService'

export default async function getPermissions() {
    const user = await authService.getUser();
    const perms = {
        userId: user?.id,
        supplierId: user?.supplierId,
        isRecipient: !!user?.isRecipient,
        isSupplier: !!user?.isSupplier,
        siteAdmin: !!user?.siteAdmin,
        suppliersAdmin: !!user?.suppliersAdmin,
        globalAdmin: !!user?.globalAdmin,
        lastReadDate: user?.lastReadDate
    };
    return perms;
}
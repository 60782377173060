import React, { Fragment, useEffect, useState } from 'react';
import {
    Alert, Button, Col, DropdownItem, DropdownToggle, DropdownMenu,
    Form, FormGroup, Input, InputGroup, InputGroupButtonDropdown, Label, Table
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { compareStrings, getFetchHeadersAsync } from '../lib/util';

export default function SupplierPurchaseOrdersManager(props) {
    const [loading, setLoading] = useState(true);
    const [purchaseOrders, setPurchaseOrders] = useState(null);
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
    const [purchaseOrderDraft, setPurchaseOrderDraft] = useState(null);
    const [purchaseOrderModified, setPurchaseOrderModified] = useState(false);
    const [settings, setSettings] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (props.supplier && !purchaseOrders) {
            getPurchaseOrdersAsync();
        }
    });

    async function getPurchaseOrdersAsync() {
        setPurchaseOrders([]);
        try {
            const headers = await getFetchHeadersAsync();

            const settingsResponse = await fetch(`api/settings/global`, headers);
            const settingsData = await settingsResponse.json();
            if (settingsData.errors || settingsData.error) throw settingsData.error || settingsData.title;

            const tempSettings = {};
            settingsData.result.forEach(item => {
                tempSettings[item.key] = item.value;
            });
            setSettings(tempSettings);

            const purchaseOrdersResponse = await fetch(`api/suppliers/${props.supplier.id}/purchaseorders`, headers);
            const purchaseOrdersData = await purchaseOrdersResponse.json();
            if (purchaseOrdersData.errors || purchaseOrdersData.error)
                throw purchaseOrdersData.error || purchaseOrdersData.title;

            setPurchaseOrders(purchaseOrdersData.result?.sort((r1, r2) => compareStrings(r1.name, r2.name)));

            if (!selectedPurchaseOrder && props.params?.purchaseOrderId) {
                const newSelection = purchaseOrdersData.result.find(p => p.id === parseInt(props.params.purchaseOrderId));
                if (!!newSelection) {
                    setSelectedPurchaseOrder(newSelection);
                    createPurchaseOrderDraft(newSelection);
                }
            }
        }
        catch (error) {
            setErrorMessage(error?.message || error);
        }

        setLoading(false);
    }

    function selectPurchaseOrder(purchaseOrder) {
        const selected = purchaseOrders?.find(p => p.id === purchaseOrder?.id);
        setSelectedPurchaseOrder(selected);
        createPurchaseOrderDraft(selected);
    }

    async function deletePurchaseOrder(purchaseOrder) {
        await savePurchaseOrderChangesAsync(purchaseOrder, 'DELETE');
    }

    function addNewPurchaseOrder() {
        const newPurchaseOrder = { id: -1 };
        setSelectedPurchaseOrder(newPurchaseOrder);
        createPurchaseOrderDraft(newPurchaseOrder);
    }

    function createPurchaseOrderDraft(original) {
        const newDraft = {
            id: original?.id,
            poNumber: original?.poNumber ?? '',
            articleNumber: original?.articleNumber ?? '',
            partNumber: original?.partNumber ?? '',
            description: original?.description ?? '',
            palletQty: original?.palletQty ?? '',
            um: original?.um ?? '',
            leadTimeValue: original?.leadTimeValue ?? settings?.leadTimeValue_default,
            leadTimeUnit: original?.leadTimeUnit ?? settings?.leadTimeUnit_default,
            supplierId: original?.supplierId ?? -1,
            isEnabled: original?.isEnabled ?? true
        }
        purchaseOrderDraftChanged(original, newDraft);
        return newDraft;
    }

    function itemHasBeenModified(original, draft) {
        if (!original && !!draft) return true; // created a new item
        // modifying item
        for (var key in draft) {
            if (!(key in original)) return true;
            let draftItem = draft[key];
            let originalItem = original[key];
            let isDate = typeof (draftItem).getTime === 'function' || typeof originalItem.getTime === 'function';
            if (!(key in original) || isDate
                ? new Date(originalItem).getTime() !== new Date(draftItem).getTime()
                : draft[key]?.toString() !== original[key]?.toString()) {
                return true;
            }
        }
        return false;
    }

    function purchaseOrderDraftChanged(original, draft) {
        let modified = itemHasBeenModified(original, draft);
        setPurchaseOrderDraft(draft);
        setPurchaseOrderModified(modified);
        // Notify parent about whether the item has been modified
        props.onChange && props.onChange(draft, modified);
    }

    async function savePurchaseOrderChangesAsync(purchaseOrder, method) {
        method = method ?? ((!purchaseOrder.id || purchaseOrder.id < 0) ? 'PUT' : 'POST');

        try {
            const response = await fetch(`api/suppliers/${props.supplier.id}/purchaseorders`,
                await getFetchHeadersAsync(method, purchaseOrder));
            const data = await response.json();
            if (data.errors || data.error) throw data.error || data.title;

            cancelPurchaseOrderChanges();
            await getPurchaseOrdersAsync();
            props.onPurchaseOrderSaved && props.onPurchaseOrderSaved();
            return true;
        }
        catch (error) {
            setErrorMessage(error?.message || error);
            return false;
        }
    }

    function cancelPurchaseOrderChanges() {
        setSelectedPurchaseOrder(null);
        setPurchaseOrderDraft(null);
        setPurchaseOrderModified(false);
        props.onChange && props.onChange(null, false);
        props.onCancel && props.onCancel();
    }

    function resetPurchaseOrderChanges() {
        const newDraft = createPurchaseOrderDraft(selectedPurchaseOrder);
        props.onChange && props.onChange(newDraft, false);
        props.onReset && props.onReset();
    }

    return loading ? <p>Loading...</p> : (
        <Fragment>
            <PurchaseOrderTable
                purchaseOrders={purchaseOrders}
                selectedPurchaseOrder={selectedPurchaseOrder}
                selectedPurchaseOrderModified={purchaseOrderModified}
                onSelectPurchaseOrder={selectPurchaseOrder}
                onDeletePurchaseOrder={deletePurchaseOrder}
                permissions={props.permissions} />
            {props.permissions?.suppliersAdmin && !selectedPurchaseOrder &&
                <Button onClick={addNewPurchaseOrder}>Add Purchase Order</Button>
            }
            {selectedPurchaseOrder && purchaseOrderDraft &&
                <PurchaseOrderForm
                    purchaseOrder={selectedPurchaseOrder}
                    purchaseOrderDraft={purchaseOrderDraft}
                    isModified={purchaseOrderModified}
                    onChange={purchaseOrderDraftChanged}
                    onSaveItemAsync={savePurchaseOrderChangesAsync}
                    onCancelEdit={cancelPurchaseOrderChanges}
                    onResetForm={resetPurchaseOrderChanges}
                    permissions={props.permissions} />
            }
            {errorMessage &&
                <div>
                    <Alert color="danger" toggle={() => setErrorMessage(null)}>
                        Error occurred! {errorMessage}
                    </Alert>
                </div>
            }
        </Fragment>
    );
}

export function PurchaseOrderTable(props) {
    return (
        <Table hover className='table table-sm'>
            <thead>
                <tr>
                    <th>PO Number</th>
                    <th>Article</th>
                    <th>Part</th>
                    <th>Description</th>
                    <th>Enabled</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {props.purchaseOrders?.map(s =>
                    <PurchaseOrderRow
                        key={s.id}
                        purchaseOrder={s}
                        enabled={!props.selectedPurchaseOrderModified}
                        onSelect={props.onSelectPurchaseOrder}
                        onDelete={props.onDeletePurchaseOrder}
                        permissions={props.permissions} />
                )}
            </tbody>
        </Table>
    );
}

export function PurchaseOrderRow(props) {
    return (
        <tr className={props.enabled ? "" : "table-disabled"}>
            <th className="align-items-center" scope="row">
                {props.purchaseOrder.poNumber}
            </th>
            <td>{props.purchaseOrder.articleNumber}</td>
            <td>{props.purchaseOrder.partNumber}</td>
            <td>{props.purchaseOrder.description}</td>
            <td>{props.purchaseOrder.isEnabled && <FontAwesomeIcon icon={faCheck} title="Enabled" />}</td>
            <td>
                {props.enabled && (
                    <Fragment>
                        {!props.permissions?.siteAdmin && (
                            <Button color="link" title="View" onClick={() => props.onSelect && props.onSelect(props.purchaseOrder)}>
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        )}
                        {props.permissions?.siteAdmin && (
                            <Button color="link" title="Edit" onClick={() => props.onSelect && props.onSelect(props.purchaseOrder)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        )}
                        {props.permissions?.suppliersAdmin && (
                            <Button color="link" title="Delete" onClick={() => props.onDelete && props.onDelete(props.purchaseOrder)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        )}
                    </Fragment>
                )}
            </td>
        </tr>
    );
}

export function PurchaseOrderForm(props) {
    const [saveSuccess, setSaveSuccess] = useState(false);

    const [leadTimeUnitSelectorOpen, setLeadTimeUnitSelectorOpen] = useState(false);

    function handleChange(event) {
        event.preventDefault();
        let { name, value, type, textContent } = event.target;
        let isCheckbox = type === "checkbox";
        let isDropdown = type === "button" && !!textContent;
        // create a copy of the draft state and send notification
        let draft = { ...props.purchaseOrderDraft };
        if (isCheckbox) value = event.target.checked;
        else if (isDropdown) {
            value = event.target.textContent;
            name = event.target.offsetParent.attributes.name.value;
        }
        draft[name] = value;
        props.onChange && props.onChange(props.purchaseOrder, draft);
    }

    async function saveItemAsync() {
        const success = await props.onSaveItemAsync(props.purchaseOrderDraft);
        if (success) {
            setSaveSuccess(true);
        }
    }

    function isModified(key) {
        return !props.purchaseOrder.id || props.purchaseOrder.id < 0
            || props.purchaseOrder[key]?.toString() !== props.purchaseOrderDraft[key]?.toString();
    }

    function toggleLeadTimeUnitSelectorOpen() {
        setLeadTimeUnitSelectorOpen(!leadTimeUnitSelectorOpen);
    }

    return (
        <Form>
            <FormGroup row>
                <Label sm={2}>PO Number</Label>
                <Col sm={10}>
                    <Input
                        type="text"
                        name="poNumber"
                        placeholder="12345"
                        value={props.purchaseOrderDraft.poNumber}
                        onChange={handleChange}
                        disabled={!props.permissions?.globalAdmin && props.purchaseOrder.poNumber}
                        className={isModified("poNumber") ? 'modified' : ''} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>Article</Label>
                <Col sm={10}>
                    <Input
                        type="text"
                        name="articleNumber"
                        placeholder="12345"
                        value={props.purchaseOrderDraft.articleNumber}
                        onChange={handleChange}
                        disabled={!props.permissions?.suppliersAdmin}
                        className={isModified("articleNumber") ? 'modified' : ''} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>Part Number</Label>
                <Col sm={10}>
                    <Input
                        type="text"
                        name="partNumber"
                        placeholder="123456789AB"
                        value={props.purchaseOrderDraft.partNumber}
                        onChange={handleChange}
                        disabled={!props.permissions?.siteAdmin}
                        className={isModified("partNumber") ? 'modified' : ''} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>Description</Label>
                <Col sm={10}>
                    <Input
                        type="text"
                        name="description"
                        placeholder="Part/Order Description"
                        value={props.purchaseOrderDraft.description}
                        onChange={handleChange}
                        disabled={!props.permissions?.suppliersAdmin}
                        className={isModified("description") ? 'modified' : ''} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>UOM</Label>
                <Col sm={10}>
                    <Input
                        type="text"
                        name="um"
                        placeholder="ea"
                        value={props.purchaseOrderDraft.um}
                        onChange={handleChange}
                        disabled={!props.permissions?.suppliersAdmin}
                        className={isModified("um") ? 'modified' : ''} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>Pallet Qty</Label>
                <Col sm={10}>
                    <Input
                        type="number"
                        name="palletQty"
                        placeholder="3000"
                        value={props.purchaseOrderDraft.palletQty}
                        onChange={handleChange}
                        disabled={!props.permissions?.suppliersAdmin}
                        className={isModified("palletQty") ? 'modified' : ''} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>Lead Time</Label>
                <Col sm={10}>
                    <InputGroup>
                        <Input
                            type="number"
                            name="leadTimeValue"
                            placeholder="7"
                            value={props.purchaseOrderDraft.leadTimeValue}
                            onChange={handleChange}
                            disabled={!props.permissions?.suppliersAdmin}
                            className={isModified("leadTimeValue") ? 'modified' : ''} />
                        <InputGroupButtonDropdown
                            addonType="append"
                            disabled={!props.permissions?.suppliersAdmin}
                            isOpen={leadTimeUnitSelectorOpen}
                            toggle={toggleLeadTimeUnitSelectorOpen}>
                            <DropdownToggle caret>{props.purchaseOrderDraft.leadTimeUnit}</DropdownToggle>
                            <DropdownMenu name="leadTimeUnit">
                                <DropdownItem onClick={handleChange}>weeks</DropdownItem>
                                <DropdownItem onClick={handleChange}>days</DropdownItem>
                            </DropdownMenu>
                        </InputGroupButtonDropdown>
                    </InputGroup>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}></Label>
                <Col sm={10}>
                    <Label>
                        <Input
                            type="checkbox"
                            name="isEnabled"
                            checked={props.purchaseOrderDraft.isEnabled}
                            disabled={!props.permissions?.suppliersAdmin}
                            onChange={handleChange}
                            className={isModified("isEnabled") ? 'modified' : ''} />
                        Enabled
                    </Label>
                </Col>
            </FormGroup>
            <div>
                {props.permissions?.siteAdmin &&
                    <Fragment>
                        <Button onClick={saveItemAsync} disabled={!props.isModified}>Save</Button>
                        <Button onClick={props.onResetForm} disabled={!props.isModified}>Reset</Button>
                    </Fragment>
                }
                <Button onClick={props.onCancelEdit}>Cancel</Button>
            </div>
            {saveSuccess &&
                <div>
                    <Alert color="success" toggle={() => setSaveSuccess(false)}>
                        Saved successfully!
                    </Alert>
                </div>
            }
        </Form>
    );
}
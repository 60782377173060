import React, { Fragment, useState } from 'react';
import { Alert, Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFlagCheckered, faPaperclip, faTruck } from '@fortawesome/free-solid-svg-icons'
import { getSimpleDateString, addDays, xhrRequest, getFetchHeadersAsync } from '../lib/util';

export default function ShipmentManager(props) {
    const [viewingFilesEntry, setViewingFilesEntry] = useState(null);
    const [viewingFilesShipment, setViewingFilesShipment] = useState(null);

    function viewAttachments(entry, shipment) {
        if (viewingFilesEntry === entry) {
            closeAttachments();
        }
        else {
            setViewingFilesEntry(entry);
            setViewingFilesShipment(shipment);
        }
    }

    function closeAttachments() {
        setViewingFilesEntry(null);
        setViewingFilesShipment(null);
    }

    return (
        <Fragment>
            <Table className='table table-sm'>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Week</th>
                        <th>Required</th>
                        <th>Ship Date</th>
                        <th>Ship Qty</th>
                        <th>Packing List</th>
                        <th>Recv Date</th>
                        <th>Recv Qty</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {!!props.schedule && props.schedule?.map((s, i) => (
                        <ShipmentTableRow
                            key={i}
                            index={i}
                            entry={s}
                            supplier={props.supplier}
                            onAddShipment={props.onAddShipment}
                            onReceiveShipment={props.onReceiveShipment}
                            onViewAttachments={viewAttachments}
                            viewingFilesShipment={viewingFilesShipment}
                            permissions={props.permissions}
                        />
                    ))}
                    {!!props.activeShipments?.map && props.activeShipments.map((as, ias) => (
                        <Fragment>
                            <tr>
                                <td colSpan="9">{`Purchase Order ${as.purchaseOrder.poNumber} (${as.purchaseOrder.partNumber})`}</td>
                            </tr>
                            {as.weeklyData.map((s, i) => (
                                <ShipmentTableRow
                                    key={s.weekData.id}
                                    index={s.weekData.id}
                                    entry={s}
                                    supplier={props.supplier}
                                    onAddShipment={props.onAddShipment}
                                    onReceiveShipment={props.onReceiveShipment}
                                    onViewAttachments={viewAttachments}
                                    viewingFilesShipment={viewingFilesShipment}
                                    permissions={props.permissions}
                                />
                            ))}
                        </Fragment>
                    ))}
                </tbody>
            </Table>
        </Fragment>
    );
}

export function ShipmentTableRow(props) {
    const [errorMessage, setErrorMessage] = useState(null);

    async function openFile(shipment, shippingDoc) {
        const supplierId = props.supplier.id;
        const purchaseOrderId = props.entry.weekData.purchaseOrderId;
        const shipmentId = shipment.shipment.id;
        const documentId = shippingDoc.id;
        const url = `api/suppliers/${supplierId}/purchaseorders/${purchaseOrderId}/shipments/${shipmentId}/files/${documentId}`;
        try {
            const xhr = await xhrRequest({
                url,
                headers: await getFetchHeadersAsync('GET'),
                onProgress: onDownloadProgress
            });
            //const headers = xhr.getAllResponseHeaders();
            const disposition = xhr.getResponseHeader('content-disposition');
            const contentType = xhr.getResponseHeader('content-type');

            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const filenameMatches = filenameRegex.exec(disposition);
            let filename = 'download';
            if (filenameMatches && filenameMatches[1])
                filename = filenameMatches[1].replace(/['"]/g, "");

            const blob = new Blob([xhr.response], { type: contentType });
            const newUrl = window.URL.createObjectURL(blob);

            var link = document.createElement('a');
            document.body.appendChild(link);
            link.style = 'display: none';
            link.href = newUrl;
            link.download = filename;
            link.click();
            window.URL.revokeObjectURL(newUrl);
        }
        catch (error) {
            setErrorMessage(error?.message || error?.statusText || !!error?.status ? `Error status code ${error.status}` : (error ?? "An unknown error occurred."));
        }
        //window.open(url, '_blank').focus();
    }

    function onDownloadProgress(ev) {
        console.log(ev.loaded);
    }

    function getStyleClassForEntry() {
        if (!props.entry.shipments) return "";
        const received = props.entry.shipments.reduce((accum, cur) => accum + cur.shipment.receivedQty, 0);
        const shipped = props.entry.shipments.reduce((accum, cur) => accum + cur.shipment.shipQty, 0);
        const required = props.entry.weekData.qtyRequired;
        if (shipped === 0) return "";
        if (shipped < required) return "alert-warning";
        if (received < required && shipped > received) return "alert-danger";
        if (received >= required) return "alert-success";
        return "";
    }

    function getStyleClassForShipment(shipment) {
        if (shipment.shipment.shipQty > shipment.shipment.receivedQty)
            return "alert-info";
        return "alert-success";
    }

    return (
        <Fragment>
            {props.entry.shipments?.length > 1
                ? (
                    <Fragment>
                        <tr className={getStyleClassForEntry()}>
                            <td>{getSimpleDateString(props.entry.weekData.releaseDate)}</td>
                            <td>{props.entry.weekData.weekInfo.weekNumber}</td>
                            <td>{props.entry.weekData.qtyRequired}</td>
                            <td>{/*{getSimpleDateString(props.entry.shipments[0].shipDate)} +*/}</td>
                            <td>{props.entry.shipments.reduce((accum, cur) => accum + cur.shipment.shipQty, 0)} (total)</td>
                            <td></td>
                            <td>{props.entry.shipments[0].shipment.receivedDate && `${getSimpleDateString(props.entry.shipments[0].shipment.receivedDate)} +`}</td>
                            <td>{props.entry.shipments.reduce((accum, cur) => accum + cur.shipment.receivedQty, 0)} (total)</td>
                            <td>
                                <ShipmentActions
                                    index={props.index}
                                    entry={props.entry}
                                    shipment={null}
                                    showAddButton={true}
                                    onViewAttachments={props.onViewAttachments}
                                    onAddShipment={props.onAddShipment}
                                    onReceiveShipment={props.onReceiveShipment}
                                    permissions={props.permissions}
                                />
                            </td>
                        </tr>
                        {props.entry.shipments.map(s => (
                            <Fragment key={s.shipment.id}>
                                <tr key={s.shipment.id} className={getStyleClassForShipment(s)}>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{getSimpleDateString(s.shipment.shipDate)}</td>
                                    <td>{s.shipment.shipQty}</td>
                                    <td>{s.shipment.packingListNumber}</td>
                                    <td>{getSimpleDateString(s.shipment.receivedDate)}</td>
                                    <td>{s.shipment.receivedQty ?? 0}</td>
                                    <td>
                                        <ShipmentActions
                                            index={props.index}
                                            entry={props.entry}
                                            shipment={s}
                                            showAddButton={false}
                                            onViewAttachments={props.onViewAttachments}
                                            onReceiveShipment={props.onReceiveShipment}
                                            permissions={props.permissions}
                                        />
                                    </td>
                                </tr>
                                {props.viewingFilesShipment === s && (
                                    <ShipmentFiles shipment={s} onOpenFile={openFile} permissions={props.permissions} />
                                )}
                            </Fragment>
                        ))}
                    </Fragment>
                ) : (
                    <Fragment>
                        <tr className={getStyleClassForEntry()}>
                            <td>{getSimpleDateString(props.entry.weekData.releaseDate)}</td>
                            <td>{props.entry.weekData.weekInfo.weekNumber}</td>
                            <td>{props.entry.weekData.qtyRequired}</td>
                            <td>{getSimpleDateString(props.entry.shipments && props.entry.shipments[0]?.shipment.shipDate)}</td>
                            <td>{props.entry.shipments && props.entry.shipments[0]?.shipment.shipQty}</td>
                            <td>{props.entry.shipments && props.entry.shipments[0]?.shipment.packingListNumber}</td>
                            <td>{getSimpleDateString(props.entry.shipments && props.entry.shipments[0]?.shipment.receivedDate)}</td>
                            <td>{props.entry.shipments && props.entry.shipments[0]?.shipment.receivedQty}</td>
                            <td>
                                <ShipmentActions
                                    index={props.index}
                                    entry={props.entry}
                                    shipment={props.entry.shipments[0]}
                                    showAddButton={true}
                                    onViewAttachments={props.onViewAttachments}
                                    onAddShipment={props.onAddShipment}
                                    onReceiveShipment={props.onReceiveShipment}
                                    permissions={props.permissions}
                                />
                            </td>
                        </tr>
                        {props.entry.shipments?.length > 0 && props.viewingFilesShipment === props.entry.shipments[0] && (
                            <ShipmentFiles shipment={props.entry.shipments[0]} onOpenFile={openFile} permissions={props.permissions} />
                        )}
                    </Fragment>
                )}
            {!!errorMessage && (
                <tr>
                    <td colSpan="9">
                        <Alert color="danger" toggle={() => setErrorMessage(null)}>
                            Error occurred! {errorMessage}
                        </Alert>
                    </td>
                </tr>
            )}
        </Fragment>
    );
}

export function ShipmentActions(props) {
    return (
        <div className="actions">
            <div>
                {props.shipment?.documents?.length > 0 && !!props.onViewAttachments &&
                    <Button color="link" onClick={() => props.onViewAttachments(props.entry, props.shipment)} title="Attachments">
                        <FontAwesomeIcon icon={faPaperclip} />
                    </Button>
                }
            </div>
            <div>
                {props.permissions.isSupplier && props.showAddButton && !!props.onAddShipment && new Date(props.entry.weekData.releaseDate) < addDays(new Date(), 8) &&
                    props.entry.shipments.reduce((accum, cur) => accum + cur.shipment.receivedQty, 0) < props.entry.weekData.qtyRequired && (
                    <Button color="link" onClick={() => props.onAddShipment(props.entry)} title="Add shipment">
                        <FontAwesomeIcon icon={faTruck} />
                    </Button>
                )}
                {props.permissions.isRecipient && props.shipment && !!props.onReceiveShipment && !(props.shipment?.shipment.receivedQty > 0) && (
                    <Button color="link" onClick={() => props.onReceiveShipment(props.entry, props.shipment)} title="Receive">
                        <FontAwesomeIcon icon={faFlagCheckered} />
                    </Button>
                )}
            </div>
        </div>
    );
}

export function ShipmentFiles(props) {
    return (
        <tr>
            <td colSpan="9">
                {props.shipment.documents.map(d => (
                    <Button key={d.id} onClick={() => props.onOpenFile(props.shipment, d)}>
                        <FontAwesomeIcon icon={faFile} />
                        {` ${d.name} (${(d.length / 1024).toFixed(1)} kb)`}
                    </Button>
                ))}
            </td>
        </tr>
    );
}
import React, { Component, Fragment } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import authService from './api-authorization/AuthorizeService';
import getPermissions from './api-authorization/Permissions.js'
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            permissions: null,
            collapsed: true
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    async populateState() {
        if (!this.state.permissions) await this.getPerms();
    }

    async getPerms() {
        const perms = await getPermissions();
        this.setState({ permissions: perms });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    SupplierNavItems(props) {
        return (
            <Fragment>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/shipments">Shipments</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/forecast">Forecast</NavLink>
                </NavItem>
            </Fragment>
        );
    }

    RecipientNavItems(props) {
        return (
            <Fragment>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/suppliers">All Suppliers</NavLink>
                </NavItem>
            </Fragment>
        );
    }

    GlobalNavItems(props) {
        return (
            <Fragment>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/supplier">My Supplier</NavLink>
                </NavItem>
            </Fragment>
        );
    }

    SiteAdminNavItems(props) {
        return (
            <Fragment>
            </Fragment>
        );
    }

    SuppliersAdminNavItems(props) {
        return (
            <Fragment>
            </Fragment>
        );
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">BRUSS Supplier Portal</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                {this.state.permissions?.isSupplier && <this.SupplierNavItems />}
                                {!!this.state.permissions?.supplierId && <this.GlobalNavItems />}
                                {this.state.permissions?.isRecipient && <this.RecipientNavItems />}
                                {this.state.permissions?.siteAdmin && <this.SiteAdminNavItems />}
                                {this.state.permissions?.suppliersAdmin && <this.SuppliersAdminNavItems />}
                                <LoginMenu>
                                </LoginMenu>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

import React, { Fragment, useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import getPermissions from './api-authorization/Permissions.js'
import { getFetchHeadersAsync } from '../lib/util'
import ShipmentsManager from './ShipmentsManager.js';

export default function MyShipments() {
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState(null);
    const [supplier, setSupplier] = useState(null);

    useEffect(() => {
        if (!permissions) getPerms();
        if (!supplier) populateSuppliers();
    });

    async function getPerms() {
        const perms = await getPermissions();
        setPermissions(perms);
    }

    async function populateSuppliers() {
        setSupplier({});

        const supplierResponse = await fetch('api/myinfo/supplier', await getFetchHeadersAsync());
        const supplierData = await supplierResponse.json();
        setSupplier(supplierData.result);

        setLoading(false);
    }



    return loading ? <Label>Loading...</Label> : (
        <Fragment>
            <h2>{supplier.name}</h2>
            <ShipmentsManager supplier={supplier} permissions={permissions} />
        </Fragment>
    );
}

import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import SuppliersManager from './components/SuppliersManager';
import MySupplierManager from './components/MySupplierManager';
import MyInfo from './components/MyInfo';
import MyForecast from './components/MyForecast';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'
import MyShipments from './components/MyShipments';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
            <AuthorizeRoute path='/myinfo' component={MyInfo} />
            <AuthorizeRoute path='/forecast' component={MyForecast} />
            <AuthorizeRoute path='/shipments' component={MyShipments} />
            <AuthorizeRoute path='/supplier' component={MySupplierManager} />
            <AuthorizeRoute path='/suppliers/:supplierId?' component={SuppliersManager} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}

import React, { Fragment, useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { getFetchHeadersAsync } from '../lib/util';

export default function MyInfo(props) {
    const [data, setData] = useState(null);
    useEffect(() => {
        async function fetchData() {
            const response = await fetch('api/myinfo/supplier', await getFetchHeadersAsync());
            setData(JSON.stringify(await response.json()));
        }
        if (!data)
            fetchData();
    });

    return !data ? <Label>Loading...</Label> : (
        <Fragment>
            <p>{data}</p>
        </Fragment>
    );
}
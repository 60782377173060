import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, FormGroup, Input, Label, Table } from 'reactstrap';
//import { Link } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { compareStrings } from '../lib/util';

export default function UsersManager(props) {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userDraft, setUserDraft] = useState(null);
    const [userModified, setUserModified] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (props.supplier && !users) {
            getUsersAsync(props.supplier.id);
        }
    });

    async function getUsersAsync(supplierId) {
        setUsers([]);
        const token = await authService.getAccessToken();
        const response = await fetch(`api/suppliers/${supplierId}/users`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        try {
            const data = await response.json();
            setUsers(data.result?.sort((r1, r2) => compareStrings(r1.firstName, r2.firstName)));
            if (data.errors || data.error) throw data.error || data.title;

        }
        catch (error) {
            setUsers([]);
            setErrorMessage(error?.message || error);
        }

        setLoading(false);
    }

    // Selecting a user sets the selectedUser item and creates a shallow draft copy
    function selectUser(user) {
        const selected = users?.find(u => u.id === user?.id);
        setSelectedUser(selected);
        createUserDraft(selected);
    }

    // Creating a new user is like selecting an existing user, but with a placeholder object
    function addNewUser() {
        let newUser = { };
        setSelectedUser(newUser);
        createUserDraft(newUser);
    }

    function onError(error) {
        setErrorMessage(error);
    }

    // Draft is a shallow copy or a new item with initialized values
    function createUserDraft(original) {
        const newDraft = {
            id: original?.id,
            firstName: original?.firstName ?? '',
            lastName: original?.lastName ?? '',
            phone: original?.phone ?? '',
            email: original?.email ?? '',
            assignmentId: original?.assignmentId,
            supplierId: original?.supplierId,
            supplierName: original?.supplierName ?? '',
            isSiteAdmin: original?.isSiteAdmin ?? false,
            isGlobalAdmin: original?.isGlobalAdmin ?? false,
            isEnabled: original?.isEnabled ?? true,
            receiveForecastEmails: original?.receiveForecastEmails ?? true,
            receiveShipmentEmails: original?.receiveShipmentEmails ?? true
        };
        userDraftChanged(original, newDraft);
        return newDraft;
    }

    function itemHasBeenModified(original, draft) {
        let modified = false;
        if (!original) return false; // no user selected
        if (!original.assignmentId) return true; // creating new user
        // modifying user
        for (var key in draft) {
            if (!(key in original) || draft[key] !== original[key]) {
                modified = true;
            }
        }
        return modified;
    }

    // Check if the draft is in 'modified' state, update the draft and modified state
    function userDraftChanged(original, draft) {
        let modified = itemHasBeenModified(original, draft);
        setUserDraft(draft);
        setUserModified(modified);
        // Notify parent about whether the user has been modified
        props.onChange && props.onChange(draft, modified);
    }

    async function deleteUser(user) {
        saveUserChangesAsync(user, "DELETE");
    }

    async function saveUserChangesAsync(user, method) {
        const token = await authService.getAccessToken();
        var query = '';
        if (!method) {
            // New item
            if (!user.assignmentId) {
                method = 'PUT';
            }
            // Existing item
            else {
                //query = ''; ///' + selectedUser.id;
                method = 'POST';
            }
        }

        try {
            const response = await fetch(`api/suppliers/${props.supplier.id}/users${query}`, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                method: method,
                body: JSON.stringify(user)
            });
            const data = await response.json();
            if (data.errors || data.error) throw data.error || data.title;

            cancelUserChanges();
            await getUsersAsync(props.supplier.id);
            props.onUserSaved && props.onUserSaved();
            return true;
        }
        catch (error) {
            onError(error.message ?? error);
            return false;
        }
    }

    function cancelUserChanges() {
        setSelectedUser(null);
        createUserDraft(null);
        props.onChange && props.onChange(null, false);
        props.onCancel && props.onCancel();
    }

    function resetUserChanges() {
        const newDraft = createUserDraft(selectedUser);
        props.onChange && props.onChange(newDraft, false);
        props.onReset && props.onReset();
    }

    return loading ? <p>Loading...</p> : (
        <Fragment>
            <UserTable
                users={users}
                selectedUser={selectedUser}
                selectedUserModified={userModified}
                onSelectUser={selectUser}
                onDeleteUser={deleteUser}
                permissions={props.permissions} />
            {props.permissions?.siteAdmin && !selectedUser &&
                <Button onClick={addNewUser}>Add User</Button>
            }
            {selectedUser &&
                <UserForm
                    user={selectedUser}
                    userDraft={userDraft}
                    isModified={userModified}
                    onChange={userDraftChanged}
                    onSaveItemAsync={saveUserChangesAsync}
                    onCancelEdit={cancelUserChanges}
                    onResetForm={resetUserChanges}
                    permissions={props.permissions} />
            }
            {errorMessage &&
                <div>
                    <Alert color="danger" toggle={() => setErrorMessage(null)}>
                        Error occurred! {errorMessage}
                    </Alert>
                </div>
            }
        </Fragment>
    );
}

export function UserTable(props) {
    return (
        <Table hover className='table table-sm'>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    {props.showSiteName && <th>Site</th>}
                    <th>Admin</th>
                    <th>Enabled</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {props.users?.map(s =>
                    <UserRow
                        key={s.id}
                        user={s}
                        enabled={!props.selectedUserModified}
                        onSelect={props.onSelectUser}
                        onDelete={props.onDeleteUser}
                        showSiteName={props.showSiteName}
                        permissions={props.permissions} />
                )}
            </tbody>
        </Table>
    );
}

export function UserRow(props) {
    return (
        <tr className={props.user.isEnabled && props.enabled ? "" : "table-disabled"}>
            <th className="align-items-center" scope="row">
                {props.user.firstName + ' ' + props.user.lastName}
            </th>
            <td>{props.user.email}</td>
            <td>{props.user.phone}</td>
            {props.showSiteName && <td>{props.user.supplierName}</td>}
            <td>{props.user.isSiteAdmin && <FontAwesomeIcon icon={faCheck} title="Site Administrator" />}</td>
            <td>{props.user.isEnabled && <FontAwesomeIcon icon={faCheck} title="Enabled" />}</td>
            <td>
                {props.permissions?.siteAdmin && props.enabled && (
                    <Button color="link" onClick={() => props.onSelect && props.onSelect(props.user)}>
                        <FontAwesomeIcon icon={faEdit} title="Edit" />
                    </Button>
                )}
                {props.permissions?.siteAdmin && props.enabled && !props.user.id && (
                    <Button color="link" onClick={() => props.onDelete && props.onDelete(props.user)}>
                        <FontAwesomeIcon icon={faTrash} title="Delete" />
                    </Button>
                )}
            </td>
        </tr>
    );
}

export function UserForm(props) {
    const [saveSuccess, setSaveSuccess] = useState(false);

    function handleChange(event) {
        //event.preventDefault();
        const { name, value, type } = event.target;
        let isCheckbox = type === "checkbox";
        // create a copy of the draft state and send notification
        let draft = { ...props.userDraft };
        draft[name] = isCheckbox ? event.target.checked : value;
        props.onChange && props.onChange(props.user, draft);
    }

    async function saveItemAsync() {
        const success = await props.onSaveItemAsync(props.userDraft);
        if (success) {
            setSaveSuccess(true);
        }
    }

    function isModified(key) {
        return !props.user.assignmentId || props.user[key] !== props.userDraft[key];
    }

    return (
        <Form>
            <FormGroup row>
                <Label sm={2}>Email</Label>
                <Col sm={10}>
                    <Input
                        type="email"
                        name="email"
                        placeholder="email@company.com"
                        value={props.userDraft.email}
                        onChange={handleChange}
                        disabled={!props.permissions?.siteAdmin || props.user.email}
                        className={isModified("email") ? 'modified' : ''} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}></Label>
                <Col sm={10}>
                    <Label>
                        <Input
                            type="checkbox"
                            name="isEnabled"
                            checked={props.userDraft.isEnabled}
                            onChange={handleChange}
                            disabled={!props.permissions?.siteAdmin || props.user.id === props.permissions?.userId}
                            className={isModified("isEnabled") ? 'modified' : ''} />
                        Enabled
                    </Label>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}></Label>
                <Col sm={10}>
                    <Label>
                        <Input
                            type="checkbox"
                            name="receiveForecastEmails"
                            checked={props.userDraft.receiveForecastEmails}
                            onChange={handleChange}
                            disabled={!props.permissions?.siteAdmin}
                            className={isModified("receiveForecastEmails") ? 'modified' : ''} />
                        Receive Forecast Emails
                    </Label>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}></Label>
                <Col sm={10}>
                    <Label>
                        <Input
                            type="checkbox"
                            name="receiveShipmentEmails"
                            checked={props.userDraft.receiveShipmentEmails}
                            onChange={handleChange}
                            disabled={!props.permissions?.siteAdmin}
                            className={isModified("receiveShipmentEmails") ? 'modified' : ''} />
                        Receive Shipment Emails
                    </Label>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}></Label>
                <Col sm={10}>
                    <Label>
                        <Input
                            type="checkbox"
                            name="isSiteAdmin"
                            checked={props.userDraft.isSiteAdmin}
                            onChange={handleChange}
                            disabled={!props.permissions?.siteAdmin || props.user.id === props.permissions?.userId}
                            className={isModified("isSiteAdmin") ? 'modified' : ''} />
                        Site Administrator
                    </Label>
                </Col>
            </FormGroup>
            {props.permissions?.globalAdmin &&
                <FormGroup row>
                    <Label sm={2}></Label>
                    <Col sm={10}>
                        <Label>
                            <Input
                                type="checkbox"
                                name="isGlobalAdmin"
                                checked={props.userDraft.isGlobalAdmin}
                                onChange={handleChange}
                                disabled={props.user.id === props.permissions?.userId}
                                className={isModified("isGlobalAdmin") ? 'modified' : ''} />
                            Global Administrator
                        </Label>
                    </Col>
                </FormGroup>
            }
            {props.permissions?.siteAdmin &&
                <div>
                    <Button onClick={saveItemAsync} disabled={!props.isModified}>Save</Button>
                    <Button onClick={props.onResetForm} disabled={!props.isModified}>Reset</Button>
                    <Button onClick={props.onCancelEdit}>Cancel</Button>
                </div>
            }
            {saveSuccess &&
                <div>
                    <Alert color="success">
                        Saved successfully!
                    </Alert>
                </div>
            }
        </Form>
    );
}